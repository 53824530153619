footer.appbar {
  background: var(--color-background-light);
  position: static;

  a {
    color: var(--color-link-text) !important;
  }

  @media (max-width: 1200px) {
    display: none;
  }
}

._short {
  background: var(--color-background-light);
  width: 100%;
  display: flex;
  justify-content: center;
  height: 88px;

  img {
    width: 150px;
    height: 60px;
    align-self: center;
  }

  @media (min-width: 1200px) {
    display: none;
  }
}

