header.appbar, footer.appbar {
  height: 88px;
  background: var(--color-white);
  box-shadow: unset;
  padding: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1200px) {
    display: none;
  }

  .logo {
    padding-left: 59px;
    width: 159px;
    height: 55px;
  }

  .menu {
    margin-left: 145px;
    margin-right: auto;
    font-weight: 700;
    letter-spacing: 0.2px;
    font-size: 15px;
    line-height: 24px;

    a {
      margin-left: 32px;
      cursor: pointer;
      color: var(--color-link-text);

      &._active {
        color: var(--color-blue);
      }

      &:hover {
        color: var(--color-blue);
      }
    }
  }

  button.primary {
    margin-right: 48px;
  }
}


.lang-menu {
  .MuiMenu-paper {
    //width: 90px;
    height: fit-content;
    max-height: fit-content;
    padding: 0;
    border: 1px solid var(--color-primary);
    transform: translate(-14px, -12px) !important;
    border-color: var(--color-placeholder);
    background: var(--color-background-light);

    @media (max-width: 1200px) {
      transform: translate(44px, -42px) !important;

      li {
        color: var(--color-text-blue);
      }
    }

    li {
      padding: 12px 13px;
      font-size: 14px;

      &:hover {
        background: var(--color-background-light);
      }
    }

    img {
      margin-right: 8px;
    }
  }
}

.header-short {
  display: none;
  z-index: 10;
  top: 16px;
  left: 16px;
  position: fixed;
  @media (max-width: 1200px) {
    display: block;
  }

  button, button:hover, button:active, button:focus {
    background: var(--color-white);
  }
}

.header-mobile-menu {
  img.logo {
    position: absolute;
    left: 0;
    margin-left: 28px;
    width: 150px;
  }

  .MuiDrawer-paper {
    padding: 0;
    border-radius: 25px;
    width: 100%;
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .close-btn {
      background: var(--color-blue);
      z-index: 100;
      position: absolute;
      top: 16px;
      right: 16px;
    }

    .menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 40%;
      margin: auto;

      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;

      a {
        color: var(--color-black);

        &._active {
          font-weight: 600;
          line-height: 24px;
        }
      }
    }

    .lang-button {
      width: fit-content;
      margin: 0 0 24px 0;
      span {
        color: var(--color-primary) !important;
      }

      path {
        fill: var(--color-primary);
      }

    }
  }
}

button.lang-button, button.lang-button:hover, button.lang-button:focus, button.lang-button:active {
  background: transparent;
  width: 54px;
  min-width: 54px;
  padding: 0;
  margin: 0 44px 0 0;
  border: none;

  .title {
    text-transform: uppercase;
    margin-right: 12px;
  }

  img {
    margin-right: 12px;
  }

}
