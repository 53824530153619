.about-part {
  box-sizing: border-box;
  position: relative;
  height: fit-content;
  margin: 30px auto 60px;
  background: var(--color-white);
  padding-bottom: 32px;

  .cards-container {
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 48px;
    @media (max-width: 1200px) {
      padding: 64px 16px;
    }

    @media (max-width: 1200px) {
      flex-direction: column;
      padding: 0 16px;
      width: calc(100% - 32px);
    }
  }

  .card {
    border-radius: 12px;
    width: 370px;
    box-shadow: 0 0 15px rgba(75, 91, 118, .1);
    display: flex;
    flex-direction: column;
    transition: .6s ease;
    cursor: pointer;

    margin-right: 24px;
    background-color: var(--color-white);

    .h3 {
      color: var(--color-title);
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
    }

    font-weight: 300;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: 1200px) {
      margin-right: 0;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .full-mode {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 290px;

      @media (max-width: 1200px) {
        display: none;
      }

      .cardBody {
        color: var(--color-blue700);
        padding-top: 24px;
      }

      img {
        width: 130px;
        height: 130px;
      }

      .title {
        color: var(--color-title);
        text-align: center;
        margin-bottom: 8px;
      }
    }
    .small-mode {
      @media (min-width: 1200px) {
        display: none;
      }

      height: 290px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 130px;
        height: 130px;
        margin-right: 16px;
      }

      .title {
        color: var(--color-title);
        text-align: center;
        margin-bottom: 8px;
      }

      .cardBody {
        color: var(--color-blue700);
        padding-top: 24px;
      }
    }

    &:hover {
      transform: translateX(5px) translateY(-14px);

      @media (max-width: 1200px) {
        transform: none;
        cursor: default;
      }
      box-shadow: 0 0 25px rgba(75, 91, 118, .2);
    }
  }
}
