.contacts-part {
  width: calc(100% - 98px);
  background: var(--color-background-light);
  padding: 64px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1200px) {
    width: calc(100% - 32px);
    padding: 64px 16px;
  }

  .contacts-part__inner {
    width: 100%;
    display: flex;
    @media (max-width: 1200px) {
      flex-direction: column;
    }

    .child {
      flex-basis: 50%;

      &:first-child {
        margin-right: 126px;
      }

      &:last-child {
        margin-left: 12px;

        .h3 {
          margin-bottom: 24px;
        }
      }

      @media (max-width: 1200px) {
        &:first-child {
          margin: 0 0 12px 0;
        }

        &:last-child {
          margin: 0;
        }

        .h3 {
          text-align: center;
        }
      }
    }

    .requisites {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
    }

    form {
      .description {
        color: var(--color-black);
      }
    }

    .inline-fields {
      display: flex;
      @media (max-width: 1200px) {
        flex-direction: column;
      }

      .email {
        margin-right: 16px;
        width: 130%;
        @media (max-width: 1200px) {
          margin: 0 0 16px 0;
          width: 100%;
        }
      }
    }

    .message {
      height: 140px;
    }

    button.primary {
      width: 204px;
      margin-top: 24px;
      @media (max-width: 1200px) {
        width: 100%;
      }
    }
  }

  .success-part {
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    button {
      margin-right: auto;
    }
  }
}

.map {
  width: 100%;
  flex-grow: 1;
  padding-top: 16px;
}
