body {
  min-width: 360px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    font-family: "Museo Sans Cyrl 300", sans-serif;
    color: var(--color-text);
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
  }

  #root {
    overflow-x: hidden;
  }
}

div.MuiPaper-root {
  padding: 24px 32px;
  border-radius: 2px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  background-color: var(--color-blue);

  .h3 {
    color: var(--color-white);
  }

  @media (max-width: 1200px) {
    padding: 16px;
  }
}

.part__header {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}

.h2 {
  display: block;
  color: var(--color-text-blue);
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 56px;
}

.h3 {
  display: block;
  color: var(--color-text-blue);
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}

.MuiCheckbox-root {
  path {
    color: var(--color-text-blue);
  }
}

.margin-bottom {
  margin-bottom: 16px !important;
}

.margin-right {
  margin-right: 16px !important;
}

._clickable {
  cursor: pointer;
}

button.MuiButton-root, button.MuiButton-root:hover {
  &._large {
    height: 40px;

    &:hover {
      background-color: var(--color-blue-hover);
    }
  }

  &.primary {
    background: var(--color-blue);
    padding: 0 16px;
    border-radius: 8px;
  }

  &.secondary {
    background: var(--color-white);
    border: 1px solid var(--color-blue);
    border-radius: 8px;

    span {
      color: var(--color-text-blue);
      font-weight: 400;
      font-size: 15px;
      line-height: 26px;
    }

    &:hover {
      span {
        color: var(--color-white);
      }

      border-color: var(--color-text-blue);
    }
  }

  &._no-boarders {
    border: none;
  }

  &._white {
    border-color: var(--color-white);
    span {
      color: var(--color-white);
    }

  }

  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 24px;

  span {
    color: var(--color-white);
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    text-transform: none;
  }

  &.Mui-disabled {
    opacity: 0.5;
  }
}
