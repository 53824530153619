html {
    --color-primary: #004DA8;
    --color-primary-light: #0453a5;
    --color-white: #FFFFFF;
    --color-text: #333333;
    --color-black: #000000;
    --color-title: #13161b;
    --color-blue700: #2c3645;
    --color-blue-hover: #0b3a69;
    --color-link-text: #4c5c75;
    --color-blue: #0f4f8d;
    --color-text-blue: #094E89;
    --color-text-background: #f9f9fb;
    --color-placeholder: #989a9d ;
    --color-header: #E6E7E8;
    --color-background-light: #f2f2f2;
    --color-yellow: #ffd200;
}
